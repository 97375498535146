import Web3 from 'web3';
// TODO fix import path after renaming
import GradOwlsCollection from '../../artifacts/GCollection.json';

declare let window: any;

class Web3Service {
  async getCollectionContract() {
    const web3 = new Web3(window.ethereum);
    const networkId = await web3?.eth.net.getId();
    if (window.ethereum && networkId === Number(process.env.REACT_APP_CHAIN_ID)) {
      await window.ethereum.enable();
      const deployedNetwork = networkId && (GradOwlsCollection as any).networks[networkId];
      const contract = web3 && new web3.eth.Contract(
        (GradOwlsCollection as any).abi,
        deployedNetwork?.address,
      );
      const contractCreator = await contract?.methods.owner().call();
      const baseURI = await contract?.methods.baseURI().call();
      const accounts = await web3?.eth.getAccounts();
      if (accounts?.[0] === contractCreator) {
        return {
          contract,
          web3,
          contractAddress: deployedNetwork?.address,
          wallet: accounts[0],
          contractCreator,
          baseURI,
        };
      }
    }
    throw new Error('connection error');
  }
}

export const web3Service = new Web3Service();
