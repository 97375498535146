import React, { useCallback, useContext, useEffect, useState } from 'react';

import { toast } from 'react-toastify';
import classNames from 'classnames';

import buttonStyle from '../../styles/buttons.module.scss';
import { GlobalContext } from '../contextWrapper/contextWrapper';

import style from './setContractOwner.module.scss';

const inputPlaceholder = 'Wallet address';

const SetContractOwner = () => {
  const { contract, wallet, web3, contractOwner } = useContext(GlobalContext);
  const [inputValue, setInputValue] = useState('');
  const [currentContractOwner, setContractOwner] = useState('');
  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  }

  const setOwnerHandler = useCallback(async () => {

    if (!web3?.utils.isAddress(inputValue)) {
      return toast('invalid address');
    }
    await contract?.methods.transferOwnership(inputValue).send({ from: wallet })
    .then(
      () => setContractOwner(inputValue)
    )
    .catch((error: any) => {
      toast(error.message);
    });
  }, [contract, web3, inputValue, wallet]);

  useEffect(() => {
    if (contractOwner) {
      setContractOwner(contractOwner);
    }
  }, [contractOwner]);

  return (
    <div className={style.balanceWrapper}>
      <div className={style.control}>
        <input
          type="text"
          className={style.walletAddressInput}
          value={inputValue}
          onChange={onChangeHandler}
          placeholder={inputPlaceholder}
        />
        <button
          type="button"
          className={classNames(buttonStyle.mainButton, buttonStyle.mainButtonActive)}
          onClick={setOwnerHandler}
        >
          Set Owner
        </button>
      </div>
      <div>
        Current Contract Owner: {currentContractOwner}
      </div>

    </div>
  );
};

export default SetContractOwner;
