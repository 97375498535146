import React, { FC, useCallback, useContext, useState } from 'react';
import classNames from 'classnames';

import buttonStyle from '../../styles/buttons.module.scss';

import style from './balance.module.scss';
import { GlobalContext } from '../contextWrapper/contextWrapper';

const inputPlaceholder = 'Wallet address';

const Balance:FC = () => {
  const { contract } = useContext(GlobalContext);
  const [inputValue, setInputValue] = useState('');
  const [count, setCountValue] = useState('');
  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => setInputValue(e.target.value);
  const checkHandler = useCallback(async () => {
    const balanceOf = await contract?.methods.balanceOf(inputValue).call();
    return setCountValue(balanceOf);
  }, [contract, inputValue]);
  return (
    <div className={style.balanceWrapper}>
      <div className={style.control}>
        <input
          type="text"
          className={style.walletAddressInput}
          value={inputValue}
          onChange={onChangeHandler}
          placeholder={inputPlaceholder}
        />
        <button
          type="button"
          className={classNames(buttonStyle.mainButton, buttonStyle.mainButtonActive)}
          onClick={checkHandler}
        >
          Check
        </button>
      </div>
      {inputValue && count && <div>
        Balance of {inputValue} is {count} items
      </div>}
    </div>
  );
};

export default Balance;
